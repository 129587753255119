import buy_fist from './../components/home/buy-fist.svg'
import checkout_bill from './../components/home/checkout-bill.svg'
import los from './../components/home/los.svg'
import bill from './../components/home/bill.svg'
import util from './../components/home/util.svg'
import cash_flow from './../components/home/cash-flow.svg'

export const LIST_OF_MENU_FIN = [
  {
    id: 1,
    title: 'Vay vốn kinh doanh',
    description: 'Giải pháp vay vốn siêu nhanh với lãi suất hấp dẫn từ 1.5%/tháng',
    img: los,
    url: '/los',
    active: true,
    _blank:false
  },
  {
    id: 2,
    title: 'Thanh toán điện tử',
    description: 'Chuyển đổi số với các phương thức thanh toán không tiền mặt hiện đại',
    url: '/online-payment',
    img: bill,
    active: true,
    _blank:false
  },
  {
    id: 3,
    title: 'Tiện ích',
    description: 'Tra cứu thông tin tài chính kịp thời, tính toán phương án vay vốn kinh doanh',
    url: '/calculator',
    active: true,
    img: util,
    _blank:false
  },
  {
    id: 4,
    title: 'Mua trước trả sau',
    description: 'Giải pháp thanh toán trả góp cho người mua hàng',
    url: '/buy-first-pay-later',
    img: buy_fist,
    active: true,
    _blank:false
  },
  // {
  //   id: 5,
  //   title: 'Thanh toán hóa đơn',
  //   description: 'Đại lý thu hộ hóa đơn và dịch vụ (Điện, nước, viễn thông, truyền hình, xổ số...)',
  //   url: '/payment-service',
  //   img: checkout_bill,
  //   active: false,
  //   _blank:false
  // },
  {
    id: 6,
    title: 'Quản lý dòng tiền',
    description: 'Các kỹ năng quản lý dòng tiền dành riêng cho nhà bán hàng',
    img: cash_flow,
    url: 'https://www.sapo.vn/blog/quan-ly-ban-hang/quan-ly-nguon-von',
    active: true,
    _blank:true
  },
]
