import package1 from '../../components/los/packge1.svg'
import package2 from '../../components/los/packge2.svg'
import package3 from '../../components/los/packge3.svg'
export const SERVICE_PACKS = [
  {
    name: 'Gói Welcome',
    detail: 'Vay vốn lên đến 70 triệu đồng với lãi suất chỉ từ 2.25%/tháng',
    description: 'Áp dụng: Khách hàng hiện hữu của Sapo trên 3 tháng',
    package: 'Welcome',
    icon: package1,
  },
  {
    name: 'Gói Loyalty',
    detail: 'Vay vốn lên đến 300 triệu đồng với lãi suất chỉ từ 1.8%/tháng',
    description: 'Áp dụng: Khách hàng hiện hữu có ghi nhận doanh thu tối thiểu 6 tháng trên Sapo',
    package: 'Loyalty',
    icon: package2,
  },
  {
    name: 'Gói Premium',
    detail: 'Vay vốn lên đến 1 tỷ đồng với lãi suất ưu đãi chỉ từ 1.5%/tháng',
    description: 'Áp dụng: Khách hàng hiện hữu của Sapo có đóng thuế trên 3 tháng',
    package: 'Premium',
    icon: package3,
  },
]
