import logoTP from '../../components/los/logoTP.svg'
import logoVP from '../../components/los/logoVP.svg'

export const LIST_PARTNER_LOGO = [
  {
    logo: logoTP,
  },
  {
    logo: logoVP,
  },
]
