/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import historyUtil from '../../common/historyUtil'
import phone from '../../components/home/phone.svg'
import HomeImage from '../../components/los-image.svg'
import { formatMoney } from '../../utils/FormatNumber'
import Header from '../header/Header'
import RegisterModal from '../register/RegisterModal'
import { LIST_PARTNER_LOGO as logos } from './Partner.js'
import { SERVICE_PACKS as packages } from './ServicePacks.js'

export default function Dashboard(props) {
  const history = useHistory()
  const [showRegisterModal, setShowRegisterModal] = useState(false)

  return (
    <Fragment>
      <Container fluid className="SapoFin">
        <Header title="Vay vốn kinh doanh" />
        <Box className="SapoMoneyContainer" style={{ background: 'none', marginTop: 100 }}>
          <Box className="SapoMoneyHeaderContainer">
            <Box className="row">
              <Box className="col-9 text-center">
                <Box className="title">
                  <h5>Tiếp cận nguồn vốn chính thống từ các đối tác uy tín của Sapo Money</h5>
                </Box>
                <Box className="subDescription" style={{ marginTop: 30 }}>
                  <span>
                    <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Lãi suất
                    cạnh tranh
                  </span>
                  <span>
                    <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Thủ tục
                    nhanh gọn
                  </span>
                  <span>
                    <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Không cần
                    thế chấp
                  </span>
                </Box>
                <Box style={{ marginTop: 30 }}>
                  <Button
                    variant="outline-primary home-button"
                    onClick={() => {
                      historyUtil(history, '/los/loan-history')
                    }}
                  >
                    Lịch sử khoản vay
                  </Button>
                  <Button
                    variant="primary main-home-button"
                    onClick={(e) => setShowRegisterModal(true)}
                  >
                    Đăng ký ngay
                  </Button>
                </Box>
              </Box>
              <Box className="col-3">
                <img src={HomeImage} alt="Vay vốn kinh doanh" className="image-los" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="feature-container">
          <Row className="feature">
            {packages.map((item, index) => {
              return (
                <Col className="paper  feature-item " style={{ width: 240 }} key={index}>
                  <Box className="icon">
                    <img src={item.icon} alt={item.name} />
                  </Box>
                  <Box className="content">
                    <Box style={{ height: 200 }} className="content-detail">
                      <Box className="title">{item.name}</Box>
                      <Box className="detail">{item.detail}</Box>
                      <hr style={{ width: 60 }} />
                      <Box className="detail">{item.description}</Box>
                    </Box>
                    <Button
                      variant="outline-primary home-button"
                      onClick={() => {
                        if (item.package === 'Welcome') {
                          history.push('/landing')
                        } else {
                          history.push('/landing/vpbank')
                        }
                      }}
                    >
                      Xem chi tiết
                    </Button>
                  </Box>
                </Col>
              )
            })}
          </Row>
        </Box>
        <Row style={{ padding: '0 17px', marginLeft: 0, marginRight: 0 }}>
          <Box className="col-6">
            <Box className="mt-4 SapoMoneyLosPayter" style={{ background: '#fff' }}>
              <Box className="SapoMoneyBlogTitle">
                <Box className="row">
                  <span className="title font-weight-bold">Hạn mức vay tối đa của bạn</span>
                </Box>
              </Box>
              <Box className="limit-content">
                <Box className="limit-amount">{formatMoney(100000000)} VND</Box>
              </Box>
            </Box>
          </Box>
          <Box className="col-6">
            <Box className="SapoMoneyLosPayter mt-4" style={{ background: '#fff' }}>
              <Box className="SapoMoneyBlogTitle">
                <Box className="row">
                  <span className="title font-weight-bold">
                    Đối tác cung cấp dịch vụ của Sapo Money
                  </span>
                </Box>
              </Box>
              <Box className="limit-content los" style={{ display: 'flex', alignItems: 'center' }}>
                {logos.map((item, index) => {
                  return (
                    <Box key={index} style={{ marginRight: '20px' }}>
                      <img src={item.logo} alt="Đối tác" />
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
        </Row>
        <Box>
          <Box className="hotline-phone-ring-wrap">
            <a href="tel:02473086880">
              <img src={phone} alt="Gọi điện thoại" width={50} />
            </a>
          </Box>
        </Box>
      </Container>
      <RegisterModal
        isProductForm={0}
        visible={showRegisterModal}
        open={() => setShowRegisterModal(true)}
        close={() => setShowRegisterModal(false)}
        loanLimit={50000000}
      />
    </Fragment>
  )
}
